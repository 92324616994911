/**
 * @fileoverview: Collection of utility functions.
 */

/**
 * 'getImgUrl' makes images in a fixed folder available in webpack.
 * @param {String} img filename, which must end with jpg, png or svg in order to be used.
 * @returns filepath of image in distributed environment
 */
export function getImgUrl(img) {
  var images = require.context(
    "@/assets/content/",
    true /* search subfolder */,
    /\.(jpg|png|svg)$/
  );
  return images("./" + img);
}
