import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/login.vue";
import picperday from "@/components/picperday.vue";

const routes = [
  {
    path: "/",
    redirect: {
      name: "login",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/picperday",
    name: "picperday",
    component: picperday,
  },
  {
    path: "/*",
    redirect: {
      name: "login",
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
