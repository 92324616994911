<template>
  <!-- <div id="nav">
    <router-link v-if="authenticated" to="/login" v-on:click="logout()" replace>
      Logout
    </router-link>
  </div> -->
  <router-view @authenticated="setAuthenticated" />
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      authenticated: false,
    };
  },
  mounted() {
    if (!this.authenticated) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout() {
      this.authenticated = false;
    },
  },
};
</script>
<style>
#app {
  min-height: 100vh;
  width: auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #b8b8b8da;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#nav {
  background-color: #000;
}
</style>
