import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

const dotenv = require("dotenv");
const config = dotenv.config();
if (config.error) {
  console.log("Could not load env file", config.error);
}

createApp(App).use(store).use(router).mount("#app");
