import { createStore } from "vuex";

export default createStore({
  state: {
    prefix: "@/assets/content/",
    imageMap: {
      1: "ersterSandkasten",
      2: "it must be love",
      3: "Oma Zeit",
      4: "Papa hat das so gemacht",
      5: "Quatsch Mütze",
      6: "Schimmelreiter",
      7: "sleeping beauty",
      8: "Bestes Auto Der Welt",
      9: "best time of the day",
      10: "die Gang",
      11: "Elbwiesen",
      12: "im Urlaub",
      13: "Ladies",
      14: "das Haus",
      15: "fang mich auf",
      16: "Basedow ist schön",
      17: "ich wars nicht",
      18: "erste Pizza",
      19: "pitsche patsche",
      20: "die Parkbank",
      21: "Speedy Gonzales",
      22: "mit Freunden am See",
      23: "die Haarologin",
      24: "das Knäul",
      25: "fauler Schneeengel",
      26: "Brumm",
      27: "die Grüne Phase",
      28: "Lockdown Eins",
      29: "bist du auch Müde",
      30: "erste Zähne",
      31: "der See",
      32: "erster Ostseeurlaub",
      33: "Quatschnasen",
      34: "Osterhilfe",
      35: "erster Laternenumzug 25.11.2019",
      36: "das waren noch Zeiten",
      37: "Blaujacken",
      38: "auf Wilden Gewässern",
      39: "Mützengesicht",
      40: "erster Radausflug",
      41: "Schlittentour",
      42: "Eisprinzessin",
      43: "die Tür",
      44: "erste Hängematte",
      45: "grosser Badgrinswettbewerb",
      46: "Geh weg",
      47: "Christmasdinner", // 16.02.22
      48: "Opas Mütze",
      49: "Plantsche",
      50: "Rabe",
      51: "Rasselbande",
      52: "sauer",
      53: "Blumenmädchen",
      54: "Schport",
      55: "Selbstständigkeit",
      56: "Zeltquatsch",
      57: "unbequemes Bett",
      58: "das Nagelstudio",
      59: "Regendinner",
      60: "mit Matilda",
      61: "und es wart Licht",
      62: "ist da was",
      63: "Käse",
      64: "Mein Sandkasten Meine Regeln",
      65: "mein Bart",
      66: "Limolady",
      67: "erstes Sushi",
      68: "mit Oma on the road",
      69: "Nachteulen",
      70: "Prinzessin",
      71: "quer im Garten",
      72: "die hat doch was vor",
      73: "Schlittentour",
      74: "absolut Cool",
      75: "GrumpyMcGrump",
      76: "Kekse",
      77: "erster Kitatag",
      78: "Operation Fernlenke",
      79: "erster Zopf",
      80: "Spaziergang",
      81: "bei den Quallen",
      82: "Papas Haare sind jetzt schön",
      83: "posen",
      84: "Kopfhörerschlumpf",
      85: "Inkognito",
      86: "KchKchKch",
      87: "Brille",
      88: "Imitation Game",
      89: "Katze",
      90: "der Ritt",
      91: "Hombis Motorradwäsche",
      92: "la Familia",
      93: "mit Franzi am See",
      94: "Nice Trio",
      95: "Badespass",
      96: "vermohnt",
      97: "zweiter Laternenumzug",
      98: "Bootfahren",
      99: "noir",
      100: "am Baum",
      101: "Nudelfreunde",
      102: "standing in the sun",
      103: "Trekka",
      104: "Waterworld",
      105: "die Fledermaus",
      106: "dress up",
      107: "rumeiern", // 17.04.22
      108: "Generationen",
      109: "Knautschgesicht",
      110: "Spaziergang mit Whiskey",
      111: "erster Maiskolben",
      112: "Schlafen",
      113: "vor dem Baum",
      114: "ich kann schon Auto fahren",
      115: "Schneemann",
      116: "Wasn das",
      117: "unkooperativ",
      118: "kleiner Bär",
      119: "Raubtierangriff",
      120: "Zen",
      121: "Krokotränen",
      122: "hungriges Einhorn",
      123: "Schlaf der Gerechten",
      124: "leckerst",
      125: "Howdy",
      126: "best place on earth",
      127: "saure Erdbeeren",
      128: "die große Müdigkeit",
      129: "der Rote Blitz",
      130: "roter als Rot",
      131: "Edith zeigt Yoga",
      132: "Schaukeln forever",
      133: "Eisschnute",
      134: "große Kleine",
      135: "Klopapier",
      136: "Teilen macht Spass",
      137: "Rotkäppchen",
      138: "lästiger Papierkram",
      139: "guckguck",
      140: "dressed up",
      141: "walking in my shoes",
      142: "verdiente heiße Schokolade",
      143: "dieDreivonderTankstelle",
      144: "unendliche",
      145: "Backshop",
      146: "am See",
      147: "Baden",
      148: "so hat sie immer geschlafen",
      149: "bester Freund",
      150: "Spielplatz",
      151: "whiskey",
      152: "schwimmen lernen",
      153: "Wuaaarrr",
      154: "haarig",
      155: "beim Frühstück",
      156: "Schlafmützen",
      157: "Partey",
      158: "Wasserflasche",
      159: "Sportunterricht",
      160: "Kuchenteig",
      161: "anhimmeln",
      162: "Rot wie Zungen",
      163: "das Reh",
      164: "schönstes Lächeln",
      165: "der Pascha",
      166: "erstes Laufrad",
      167: "der Platz",
      168: "Seepferdchen",
      169: "Weihnachtsquatsch",
      170: "ich kann das selber",
      171: "das Duo",
      172: "ich auch Opa",
      173: "hipster",
      174: "der Erste Film",
      175: "mit Lina",
      176: "fauler Schneeengel 2",
      177: "Graffiti",
      178: "Kutsche",
      179: "hexhex",
      180: "gekicher",
      181: "Finger",
      182: "erster Milchschaum",
      183: "Geburtstagskuchen",
      184: "zweiter Geburtstag", // 03.07.22
      185: "dritter Geburtstag",
      186: "Geburtstagsgeschenkesichtung",
      187: "Geschenke auspacken",
      188: "Hängematte",
      189: "",
      190: "",
      191: "",
      192: "Hängematte",
      193: "Guck Mal", //12.07.22
      194: "On Tour",
      195: "Pommes",
      196: "Reisebekanntschaft",
      197: "Action Elf",
      198: "Eis Duo",
      199: "Eis Duo Nochmal",
      200: "Freunde",
      201: "Jonglieren",
      202: "Pinselino",
      203: "Streching",
      204: "Uuuu",
      205: "Bedacht",
      206: "Chraam",
      207: "Eierei",
      208: "Abwäscher",
      209: "Opa erklärt",
      210: "Hi",
      211: "mehr Vitamine",//30.07
      212: "Tour de France",
      213: "Kiesel",
      214: "Vitamine",
      215: "Einkaufsliste",
      216: "Vier Masketiere",
      217: "Randale",
      218: "Mützenkasper",
      219: "ChrPüh",
      220: "Da lang",
      221: "Schade keine Seifenblasen",
      222: "Am See",
      223: "Lesestunde",
      224: "kleine Landstreicherin",
      225: "Die Jugend von heute",
      226: "Da lang", //14.08.22
      227: "",
      228: "",
      229: "",
      230: "Da lang",
      231: "auf dem Tisch gesungen", //19.08
      232: "rum hängen",
      233: "Keck",
      234: "Schlafabteil",
      235: "Cousin Lenni",
      236: "das bleibt unter uns",
      237: "das Eichhörnchen",
      238: "Joggen",
      239: "Mamas Post",
      240: "Ringelsöckchen",
      241: "Vulkan",
      242: "Schminkstunde",
      243: "Beste",
      244: "der Hunger danach",
      245: "der Nuckel",
      246: "von oben",
      247: "ich hab den Seifenblasenschein",
      248: "Muschelsuche",
      249: "Schnarch",
      250: "Anschnallen nicht vergessen",
      251: "warten auf die Bahn",
      252: "Grinsebacken",
      253: "beste Mama",
      254: "KO",
      255: "diese Bäckchen",
      256: "Funkeln im Himmel", // 13.09
      257: "",
      258: "",
      259: "",
      260: "",
      //
      351: "",
      352: "Barnsey kommt an", //18.12.
      353: "der Bart",
      354: "erster Keks",
      355: "Zug der Schwalbe",
      356: "Entdeckung der Wut",
      357: "Geschwindigkeitsjunky",
      358: "der Elf", //24.12
      359: "Weihnachten 2019",
      360: "Entdeckung der Wut",
      361: "crazy christmas",
      362: "Haukes erster Besuch",
      363: "Ich hab die Creme gefunden",
      364: "Sandwichfreunde", // 31.12.
      365: "GrumpyMcGrump",
      6666: "eda", //something went wrong!
    },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
