<template>
  <div class="center-screen">
    <img class="background" :src="background" />
    <div class="loginwindow" id="login">
      <h2 style="padding: 10px">Login</h2>
      <input
        type="password"
        name="password"
        v-model="input.password"
        placeholder="Passwort"
      />
      <button type="button" v-on:click="login()">Login!</button>
      <div class="contact">Kontakt: lb.myrequest@gmail.com</div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { getImgUrl } from "@/utils/utils.js";
export default {
  name: "Login",
  data() {
    return {
      background: getImgUrl("background.jpg"),
      input: {
        password: "",
      },
    };
  },
  methods: {
    login() {
      if (this.input.password != "") {
        if (this.input.password == process.env.VUE_APP_ACCESS_CONTROL) {
          this.$emit("authenticated", true);
          this.$router.replace({ name: "picperday" });
        } else {
          console.log("The password is incorrect");
        }
      } else {
        console.log("A password must be present");
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 510px) {
  .loginwindow {
    max-width: 80vw;
  }
  button {
    margin: 0.5em;
  }
}

#login {
  width: 500px;
  border: 1px solid #4b4b4b52;
  border-radius: 8px 8px 8px 8px;
  background-color: #ffffff83;
  padding: 15px;
  z-index: 10;
  color: rgba(0, 0, 0, 0.623);
}
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  min-width: 100vw;
}
.background {
  max-width: auto;
  max-height: auto;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: cover;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.55)
  );
}
input {
  font-size: 0.8em;
  padding: 0.8em;
  margin: auto;
  border-radius: 8px 8px 8px 8px;
}
button {
  font-size: 0.8em;
  padding: 0.8em;
  margin-left: 0.5em;
  border-radius: 8px 8px 8px 8px;
}
.contact {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1em;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: rgb(253, 253, 253);
  text-shadow: 0em 1px 2px rgba(77, 77, 77, 0.534);
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
